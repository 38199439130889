import React from 'react';
import './Downloadpage.css';
import appstore from "../../Assets/App Store.png";
import playstore from "../../Assets/Play Store.png";
import { Link } from 'react-router-dom';
import listinpix from "../../Assets/Main Logo/listingpix.webp";
import listinpixphotographer from "../../Assets/Main Logo/listingpix photographer.webp";
import listinpixcamera from "../../Assets/Main Logo/listingpix camera.webp";

const appData = [
    {
        icon: listinpix,
        title: 'ListingPix',
        description: 'ListingPix offers a convenient Photographer Marketplace where you can easily find local photographers to capture and deliver high-quality real estate photos. With packages starting at just $89, ListingPix provides an affordable solution for stunning property visuals that elevate your listings.',
        appleLink: 'https://apps.apple.com/us/app/listingpix/id6475031160',
        googleLink: 'https://play.google.com/store/apps/details?id=com.listingpix.customer'
    },
    {
        icon: listinpixphotographer,
        title: 'ListingPix Photographer',
        description: 'The ListingPix Photographer app empowers real estate photographers to sign up, build their portfolio, manage appointments, and capture stunning real estate images for our national clients. Earn money by completing quick, convenient shoots and delivering high-quality visuals that meet the needs of real estate professionals.',
        appleLink: 'https://apps.apple.com/us/app/listingpix-photographer/id6475029859',
        googleLink: 'https://play.google.com/store/apps/details?id=com.listingpix.photographer'
    },
    {
        icon: listinpixcamera,
        title: 'ListingPix Camera',
        description: 'The ListingPix Camera app lets anyone capture stunning HDR images enhanced by ListingPix’s AI technology. Simply pay per credit to download images, with prices ranging from $0.60 to $1 per credit. Virtually stage empty rooms for as little as $3 per image, making it a perfect, affordable option for “coming soon” photos or listings that don’t require a full professional shoot.',
        appleLink: 'https://apps.apple.com/us/app/listingpix-real-estate-camera/id6475031981',
        googleLink: null // No Google Play link for this app
    }
];

const Downloadpage = () => {
    return (

        <div className="downlaodpageparent relative">
            <Link to="/"> <div className='backtohome'>&#x2190; Back to Home</div></Link>
            <div className="downloaduppercontainer">Download Our Apps</div>
            <div className="download-page">
                {appData.map((app, index) => (
                    <div className="app-card" key={index}>
                        <img src={app.icon} alt={`${app.title} icon`} className="app-icon" />
                        <h3 className="app-title">{app.title}</h3>
                        <p className="app-description">{app.description}</p>
                        <div className="downloadbuttonwidth flex overflow-x-hidden">
                            <a
                                href={app.appleLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="appstore"
                                    src={appstore}
                                    alt="Download on Apple App Store"
                                />
                            </a>
                            {app.googleLink && (
                                <a
                                    href={app.googleLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="playstore"
                                        src={playstore}
                                        alt="Download on Google Play Store"
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Downloadpage;
