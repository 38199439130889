import React from "react";
import "./Footer.css";
import insta from "../../Assets/Instagram.png";
import facebook from "../../Assets/Facebook.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footercontainer" id="footer">
        <div className="footerrowone flex">
          <div className="frcoloumnone ">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
              viewport={{ once: true }}
              className="ltalkparent "
            >
              <div className="ltalktitle">Let’s Talk</div>
              <div className="ltalkdesc">
                Send us an email or start a conversation by filling the form
                below.
              </div>
            </motion.div>

            <div className="my-16">
              <hr />
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
              viewport={{ once: true }}
              className="socialparent "
            >
              <div className="followtitle">Follow Us :</div>
              <div className="followicon gap-12 flex">
                <div className="insta">
                  <Link to="https://instagram.com/listingpix_ai"
                    target="_blank">
                    <img src={insta} alt="instagran" />
                  </Link>
                </div>
                <div className="facebook">
                  <Link
                    to="https://www.facebook.com/listingpixai"
                    target="_blank"
                  >
                    <img src={facebook} alt="facebook" />
                  </Link>
                </div>

              </div>
            </motion.div>
          </div>
          <div className="overflow-x-hidden">
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
              viewport={{ once: true }}
              className="frcoloumntwo p-8 "
            >
              <div className="nameemail flex">
                <div className="name flex flex-col">
                  <label htmlFor="fullname ">
                    Full Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder="John David"
                    autoComplete="off"
                  />
                </div>
                <div className="email flex flex-col">
                  <label htmlFor="fullemail">
                    Your Email<sup>*</sup>
                  </label>
                  <input
                    type="email"
                    placeholder="example@yourmail.com"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="phonesubject flex ">
                <div className="phoneform flex flex-col">
                  <label htmlFor="fullphone">
                    Phone<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder="(000) 123 456"
                    autoComplete="off"
                  />
                </div>
                <div className="project flex flex-col">
                  <label htmlFor="fullproject">
                    Your Email<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder="ex. project "
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="leaveusmessage flex flex-col">
                <label htmlFor="leaveus">
                  Leave Us A Message <sup>*</sup>
                </label>
                <input
                  type="text"
                  className="message"
                  placeholder="Hello there,I would like to talk about how to..."
                  autoComplete="off"
                />
              </div>
              <div className="sendbtn">
                <button className="sendtext">Send Message</button>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="hr m-auto my-16">
          <hr />
        </div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          viewport={{ once: true }}
          className="footerrowtwo flex justify-between m-auto "
        >
          <div className="copy">
            ListingPix © {currentYear}. All rights reserved
          </div>
          <div className="cookie">
            {/* Cookies &nbsp;&nbsp; / &nbsp;&nbsp;Contact Sales
            &nbsp;&nbsp;/&nbsp;&nbsp;Security&nbsp;&nbsp; /&nbsp;&nbsp; */}
            <Link
              to="https://app.termly.io/document/terms-of-service/b40d32ba-3b75-4191-b1eb-063f76dac7ae"
              target="_blank"
            >
              Terms of Service
            </Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <Link
              to="https://app.termly.io/document/privacy-policy/c61f9404-ce40-40a3-a8f8-5dbbb420c60a"
              target="_blank"
            >
              Privacy Statement
            </Link>
            &nbsp;&nbsp;
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Footer;
